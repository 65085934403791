//对于axios进行二次封装
import axios from "axios";
let requests = axios.create({
  //基础路径
  baseURL: "https://chcdzapi.lychxny.com",
  // baseURL: "http://192.168.10.89:9999",
  //请求不能超过10S
  timeout: 10000,
});
 
//请求拦截器（可以在请求发出去之前，做一些事情）
requests.interceptors.request.use((config) => {
  return config;
});
 
//响应拦截器（在数据返回之后，做一些事情）
requests.interceptors.response.use(
  (res) => {
    //相应成功
    return res.data;
  },
  (err) => {
    //响应失败
    console.log(err);
    // alert("服务器响应数据失败");
  }
);
export default requests;