export const changeREM = () => {
  const fontBase = 16; // 基础字体大小
  const canvasWidth = 1920; // 画布大小宽
  const canvasHeight = 1080; // 画布大小宽
  const clientWidth = window.innerWidth; // 屏幕大小宽
  const clientHeight = window.innerHeight; // 屏幕大小高
  const scale = Math.min((clientWidth / canvasWidth), (clientHeight / canvasHeight)); // 最小比例
  const targetFontSize = fontBase * scale; // 计算需要设置的字体大小
  // 设置根节点字体大小 用于改变REM
  document.documentElement.style.fontSize = targetFontSize + 'px';
  }