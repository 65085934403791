<template>
  <div id="app">
    <div class="bgc">
      <div style="text-align: center">
        <img src="./assets/top.png" alt="" style="height: 7vh" />
      </div>
      <el-row>
        <el-col :span="5" style="position: relative; background-color: #0b234c">
          <h4 style="text-align: center">
            <img src="./assets/biaoti/zuo.png" alt="" /><span
              style="padding: 0 10px; color: #00deff; font-size: 16px"
              >运营数据</span
            ><img src="./assets/biaoti/you.png" alt="" />
          </h4>
          <div
            style="
              color: #81c9fc;
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 26px;
              width: 230px;
              font-size: 14px;
              border: 1px solid #27436d;
              padding: 5px;
              margin: auto;
              margin-top: 8px;
            "
            v-for="(item, index) in operList"
            :key="index"
          >
            <div style="display: flex; align-items: center">
              <img
                style="height: 10px; padding: 0 10px"
                src="./assets/yunyingshuju/yuan.png"
                alt=""
              />{{ item.text }}
            </div>
            <div style="color: #fcf15c">{{ item.num }}</div>
          </div>
          <img src="./assets/biankuang/1.1.png" alt="" class="bg1" />
          <img src="./assets/biankuang/2.2.png" alt="" class="bg2" />
          <img src="./assets/biankuang/3.3.png" alt="" class="bg3" />
          <img src="./assets/biankuang/4.4.png" alt="" class="bg4" />
        </el-col>
        <el-col :span="13">
          <div style="width: 100%; height: 55vh">
            <el-amap
              class="amap-box"
              vid="amapBlueDemo"
              :events="darkblueMapEv"
            >
              <el-amap-marker
                class="_marker"
                v-for="(item, index) in markers"
                :key="index"
                :position="item.position"
                :icon="icons"
                :events="item.events"
              >
              </el-amap-marker>
              <el-amap-info-window
                v-if="window"
                :position="window.position"
                :visible="window.visible"
                :content="window.content"
                :offset="window.offset"
              ></el-amap-info-window>
            </el-amap>
          </div>
          <div>
            <h4 style="text-align: center">
              <img src="./assets/biaoti/zuo.png" alt="" />
              <span style="padding: 0 10px; color: #00deff; font-size: 16px"
                >近30日营收</span
              >
              <img src="./assets/biaoti/you.png" alt="" />
            </h4>
            <div style="width: 100%; height: 35vh" ref="echart"></div>
          </div>
        </el-col>
        <el-col
          :span="5"
          style="
            background-color: #0b234c;
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
          "
        >
          <div class="kuang kuang0">
            <img
              src="./assets/shuju/quanzhanyingshou.png"
              alt=""
              style="height: 55px; padding-left: 25px"
            />
            <div>
              <div style="color: #20fdfa; font-size: 20px">
                {{ perationalData
                }}<span style="color: #20fdfa; font-size: 12px">元</span>
              </div>
              <p style="color: white">全站营收</p>
            </div>
          </div>
          <h4 style="text-align: center">
            <img src="./assets/biaoti/zuo.png" alt="" />
            <span style="padding: 0 10px; color: #00deff; font-size: 16px"
              >基本信息</span
            >
            <img src="./assets/biaoti/you.png" alt="" />
          </h4>
          <div class="kuang kuang1">
            <img
              src="./assets/kuang/2.png"
              alt=""
              style="height: 30px; padding-left: 20px"
            />
            <div>
              <div style="color: #20fdfa; font-size: 18px; width: 150px">
                {{ revenueData.thisMonthnewUser }}
              </div>
              <p style="color: white">本月新用户</p>
            </div>
          </div>
          <div class="kuang kuang2">
            <img
              src="./assets/kuang/1.png"
              alt=""
              style="height: 30px; padding-left: 20px"
            />
            <div>
              <div style="color: #c51c2f; font-size: 18px; width: 150px">
                {{ revenueData.thisMoney }}
              </div>
              <p style="color: white">本月营收</p>
            </div>
          </div>
          <div class="kuang kuang3">
            <img
              src="./assets/kuang/3.png"
              alt=""
              style="height: 30px; padding-left: 20px"
            />
            <div>
              <div style="color: #ffa900; font-size: 18px; width: 150px">
                {{ revenueData.lastMoney }}
              </div>
              <p style="color: white">上月营收</p>
            </div>
          </div>
          <h4 style="text-align: center">
            <img src="./assets/biaoti/zuo.png" alt="" /><span
              style="padding: 0 10px; color: #00deff; font-size: 16px"
              >最新订单</span
            ><img src="./assets/biaoti/you.png" alt="" />
          </h4>
          <dv-scroll-board :config="config" style="width: 90%; height: 255px" />
          <img src="./assets/biankuang/1.1.png" alt="" class="bg1" />
          <img src="./assets/biankuang/2.2.png" alt="" class="bg2" />
          <img src="./assets/biankuang/3.3.png" alt="" class="bg3" />
          <img src="./assets/biankuang/4.4.png" alt="" class="bg4" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { changeREM } from '@/unilts/view'
import {
  getBasicInformation,
  getLatestOrder,
  getMapInformation,
  getOperationalData,
  getChargeGun,
  getRevenueTrend,
  getList,
} from './api/request'
export default {
  data() {
    return {
      stionName: '充电桩',
      stionAll: '0',
      outStion: '0',
      autoStion: '0',
      charing: '0',
      idleConnected: '0',
      faultNumber: '0',
      markers: [],
      windows: [],
      window: '',
      mapList: [],
      isOpen: false,
      config: {
        header: ['手机号', '充电量', '消费金额'],
        data: [],
      },
      operList: [
        { num: 0, text: '总用户数量' },
        { num: 0, text: '场站数量' },
        { num: 0, text: '今日充电金额(元)' },
        { num: 0, text: '昨日充电金额(元)' },
        { num: 0, text: '今日服务费(元)' },
        { num: 0, text: '昨日服务费(元)' },
        { num: 0, text: '今日累计充电量' },
        { num: 0, text: '今年累计充电量' },
        { num: 0, text: '正在使用的枪数量' },
        { num: 0, text: '充电桩数量' },
        { num: 0, text: '昨日充电人数' },
        { num: 0, text: '今日充电人数' },
      ],
      icons: require('@/assets/large.png'), //导入坐标样式
      darkblueMapEv: {
      },
      //基本信息
      revenueData: {
        lastMoney: '', //上个月营收
        thisMoney: '', //本月营收
        thisMonthnewUser: 0, //本月新用户
      },
      //全站营收
      perationalData: 0,
      //近30日营收
      thirtyRevenueData: {
        ordinate: [], //纵坐标
        abscissa: [], //横坐标
      },
    }
  },
  created() {
    this.getBasicInformation(),
      this.getMapInformation(),
      this.getLatestOrder(),
      this.getOperationalData(),
      this.getChargeGun(),
      this.getRevenueTrend(),
      changeREM()
  },
  mounted() {
    setInterval(() => {
      this.getBasicInformation(),
        // this.getMapInformation(),
        this.getLatestOrder(),
        this.getOperationalData(),
        this.getChargeGun(),
        this.getRevenueTrend()
    }, 60000)
  },
  methods: {
    async getChargeGun() {
      //运营数据
      const res = await getChargeGun()
      this.operList[0].num = res.result.userNum
      this.operList[1].num = res.result.stationNum
      this.operList[2].num = res.result.toDayChargeMoney
      this.operList[3].num = res.result.yesterdayChargeMoney
      this.operList[4].num = res.result.toDayServiceCharge
      this.operList[5].num = res.result.yesterdayServiceCharge
      this.operList[6].num = res.result.toDayNum
      this.operList[7].num = res.result.toYearNum
      this.operList[8].num = res.result.underCharge
      this.operList[9].num = res.result.sum
      this.operList[10].num = res.result.yesterdayChargeNum
      this.operList[11].num = res.result.toDayChargeNum
    },
    async getOperationalData() {
      //全站营收
      const res = await getOperationalData()
      this.perationalData = res.result
    },
    async getBasicInformation() {
      //基本信息
      const res = await getBasicInformation()
      this.revenueData = res.result
    },
    async getMapInformation() {
      //地图数据
      try {
        let markers = []
        let windows = []
        let that = this
        const res = await getMapInformation()
        res.result.forEach(async (item, index) => {
          markers.push({
            position: [parseFloat(item.longitde), parseFloat(item.latitude)],
            events: {
              click() {
                that.windows.forEach((window) => {
                  window.visible = false //关闭窗体
                })
                that.window = that.windows[index]
                that.$nextTick(() => {
                  that.window.visible = true //点击点坐标，出现信息窗体
                })
              },
            },
          })
          const res = (await getList(item.name)).result.records[0]
          that.stionName = res.name ? res.name :'充电桩'
          that.stionAll = res.dcPileNum + res.acPileNum
          that.autoStion = res.acLeisureNum + res.dcLeisureNum //空闲
          that.charing = res.dcUseNum + res.acUseNum //使用中
          that.faultNumber = res.dcFaultNum + res.acFaultNum //
          that.outStion = res.dcOffineNum + res.acOffineNum //离线
          that.idleConnected = res.dcFreeconnNum + res.acFreeconnNum //空闲已连接
          windows.push({
            position: [parseFloat(item.longitde), parseFloat(item.latitude)],
            content: `<div style="width: 240px; background-color: #fff;">
              <p style="color: #717171; font-weight: 800">${that.stionName}</p>
              <p style="color: #5c7efd;">充电桩总数:${
                that.stionAll || '0'
              }个</p>
              <p style="color: #717171; font-weight: 800">充电枪离线:${
                that.outStion
              }个</p>
              <p style="color: #5c7efd; font-weight: 800">充电枪空闲:${
                that.autoStion
              }个</p>
              <p style="color: #51cfba; font-weight: 800">充电枪充电中:${
                that.charing
              }个</p>
              <p style="color: #000000; font-weight: 800">充电枪空闲已连接:${
                that.idleConnected
              }个</p>
              <p style="color: #fe3535; font-weight: 800">充电枪故障:${
                that.faultNumber
              }个</p>
              <img style="position: absolute;
                    right: 10px;
                    bottom: 10px;
                    width: 130px;" src="https://chonghongxinnengyuan.obs.cn-north-4.myhuaweicloud.com/1697731200000/FmeNmEfldjFU37c1254fd0845fc771000accaf94670c_1697781556702.jpg" />
               </div>`, //内容
            offset: [3, -25], //窗体偏移
            visible: false, //初始是否显示
          })
        })
        this.markers = markers //  加点
        this.windows = windows //加弹窗
      } catch (error) {
        console.log(error)
      }
    },
    async getLatestOrder() {
      //列表
      const res = await getLatestOrder({
        pageNo: 1,
        pageSize: 5,
      })
      let arr = []
      res.result.records.forEach((item) => {
        arr.push([item.userPhone, item.chargeAmount, item.payMoney])
      })
      this.config = {
        //滚动列表配置
        headerBGC: '',
        oddRowBGC: '',
        evenRowBGC: '',
        columnWidth: [20],
        index: true,
        header: ['手机号', '充电量', '消费金额'],
        data: arr,
      }
    },
    async getRevenueTrend() {
      //图表
      this.thirtyRevenueData.abscissa = []
      this.thirtyRevenueData.ordinate = []
      const res = await getRevenueTrend()
      res.result.forEach((item) => {
        this.thirtyRevenueData.abscissa.push(item.dayStr.substring(5))
        this.thirtyRevenueData.ordinate.push(item.money)
      })
      this.initChart()
    },
    initChart() {
      const bar = this.$echarts.init(this.$refs.echart)
      bar.setOption({
        xAxis: {
          data: this.thirtyRevenueData.abscissa,
          axisLine: { lineStyle: { color: '#20fdfa' } },
        },
        yAxis: {
          show: true,
          axisLine: { lineStyle: { color: '#20fdfa' } },
        },
        grid: {
          top: '5%',
        },
        series: [
          {
            data: this.thirtyRevenueData.ordinate,
            type: 'line',
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'blue', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(128, 128, 128, 0.5)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      })
    },
  },
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
.amap-icon img,
.amap-marker-content img {
  width: 3vh;
  height: 3vh;
}
</style>
<style scoped>
/* 全局背景图片 */
.bgc {
  background-image: url(./assets/background.png);
  overflow: hidden;
}
span {
  color: #8f9295;
}
.el-row {
  display: flex;
  justify-content: space-between;
  height: 93vh;
  padding-bottom: 15px;
}
.kuang {
  height: 60px;
  width: 230px;
  display: flex;
  align-items: center;
  background-size: 230px;
  margin-top: 10px;
}
.kuang img {
  margin-right: 15px;
}
.kuang0 {
  height: 99px;
  width: 260px;
  background-size: 260px;
  background-image: url(./assets/shuju/bj.png);
}
.kuang1 {
  background-image: url(./assets/kuang/01.png);
}
.kuang2 {
  background-image: url(./assets/kuang/02.png);
}
.kuang3 {
  background-image: url(./assets/kuang/03.png);
}
.bg1 {
  position: absolute;
  top: 0;
  left: 0px;
}
.bg2 {
  position: absolute;
  top: 0;
  right: 0px;
}
.bg3 {
  position: absolute;
  bottom: 0;
  left: 0px;
}
.bg4 {
  position: absolute;
  bottom: 0;
  right: 0px;
}
h4 {
  margin: 14px 0;
}
h4 img {
  width: 54px;
}
::v-deep(.header-item) {
  border-bottom: 1px solid #134fa5;
}
::v-deep(.row-item) {
  background: none !important;
  border-bottom: 1px solid #324362;
  height: 32px !important;
  color: #6eb5fd;
}
</style>
