import requests from '@/unilts/http'
//获取营收数据
export function getBasicInformation(){
    return requests({
        url:'/charg-pile-system/sys/largeScreen/getBasicInformation',
        method:'get'
    })
}
//获取最新订单
export function getLatestOrder(params){
    return requests({
        url:`/charg-pile-system/sys/largeScreen/getLatestOrder?pageNo=${params.pageNo}&pageSize=${params.pageSize}`,
        method:'get'
    })
}
//获取地图信息
export function getMapInformation(){
    return requests({
        url:'/charg-pile-system/sys/largeScreen/getMapInformation',
        method:'get'
    })
}
//获取全站营收
export function getOperationalData(){
    return requests({
        url:'/charg-pile-system/sys/largeScreen/getTotalStationRevenue',
        method:'get'
    })
}
//获取充电枪数据
export function getChargeGun(){
    return requests({
        url:'/charg-pile-system/sys/largeScreen/getChargeGun',
        method:'get'
    })
}
//获取近30日营收数据
export function getRevenueTrend(){
    return requests({
        url:'/charg-pile-system/sys/largeScreen/getRevenueTrend',
        method:'get'
    })
}
//获取坐标详情
export function getList(str){
    return requests({
        url:`/charg-pile-system/sys/largeScreen/getChargStationList`,
        method:'get',
        params: {
            pageNo: 1,
            pageSize: 1,
            stationName: str,
          },
    })
}

