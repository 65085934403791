import Vue from 'vue'
import App from './App.vue'
import { Col, Row, Divider, TableColumn, Table } from 'element-ui'
import VueAMap from 'vue-amap'
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css'
import moment from 'moment/moment'
Vue.prototype.moment = moment
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)
VueAMap.initAMapApiLoader({
  key: '7963089f2e42960ce9cb948e721373ed',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'MarkeClusterer',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
  ],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4',
})
Vue.use(VueAMap)
Vue.use(Col)
Vue.use(Row)
Vue.use(Divider)
Vue.use(TableColumn)
Vue.use(Table)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
new Vue({
  render: (h) => h(App),
}).$mount('#app')
